
import affiliatemarketing from './Services_icons/DigitalMarketingIcons/affiliatemarketing.webp'
import SearchEngineOptimization from './Services_icons/DigitalMarketingIcons/searchengineoptimization.webp'
import ContentMarketing from './Services_icons/DigitalMarketingIcons/contentmarketing.webp'
import SocialMediaMarketing from './Services_icons/DigitalMarketingIcons/socialmediamarketing.webp'
import EmailMarketing from './Services_icons/DigitalMarketingIcons/emailmarketing.webp'
import PayPerClickAdvertising from './Services_icons/DigitalMarketingIcons/payperclick.webp'
import AnalyticsandDataAnalysis from './Services_icons/DigitalMarketingIcons/analysisanddata.webp'
import ConversionRateOptimization from './Services_icons/DigitalMarketingIcons/conversionrateoptimization.webp'
import MobileMarketing from './Services_icons/DigitalMarketingIcons/mobilemarketing.webp'
import VideoMarketing from './Services_icons/DigitalMarketingIcons/videomarketing.webp'
import LocalSEOandMarketing from './Services_icons/DigitalMarketingIcons/LocalSEOandMarketing.webp'
import EcommerceMarketing from './Services_icons/DigitalMarketingIcons/EcommerceMarketing.webp'
import MarketingAutomation from './Services_icons/DigitalMarketingIcons/MarketingAutomation.webp'
import RemarketingandRetargeting from './Services_icons/DigitalMarketingIcons/RemarketingandRetargeting.webp'
import InfluencerMarketing from './Services_icons/DigitalMarketingIcons/influencer.webp'

import BusinessWebsites from './Services_icons/WebDevelopment/businesswebsite.webp'
import Ecommerce from './Services_icons/WebDevelopment/ecommerce.webp'
import WebRecamp from './Services_icons/WebDevelopment/WebRevamp.webp'
import CRMERM from './Services_icons/WebDevelopment/crmermpannel.webp'

import  BannerDesign from './Services_icons/MultiMedia/Banner Design.webp';
import  BrandIdentityDesign from './Services_icons/MultiMedia/Brand Identity Design.webp'
import  BrochureDesign from './Services_icons/MultiMedia/Brochure Design.webp'
import  BusinessCard from './Services_icons/MultiMedia/Business Card.webp'
import  eBookandPrintBook from './Services_icons/MultiMedia/eBook and Print Book Cover Design.webp'
import  IllustationServices from './Services_icons/MultiMedia/Illustation Services.webp'
import  InfographicDesign from './Services_icons/MultiMedia/Infographic Design.webp'
import  logodesign from './Services_icons/MultiMedia/logodesign.webp'
import  MotionGraphics from './Services_icons/MultiMedia/Motion Graphics and Animation.webp'
import  PackagingDesign from './Services_icons/MultiMedia/Packaging Design.webp'
import  PhotoEditing from './Services_icons/MultiMedia/Photo Editing.webp'
import  PosterandFlyerDesign from './Services_icons/MultiMedia/Poster and Flyer Design.webp'
import  PrintDesign from './Services_icons/MultiMedia/Print Design.webp'
import  RenderingandVisualizationBannerDesign from './Services_icons/MultiMedia/Rendering and Visualization.webp'
import  SignageandBillboardDesign from './Services_icons/MultiMedia/Signage and Billboard Design.webp'
import  SocialMediaGraphics from './Services_icons/MultiMedia/Social Media Graphics.webp'
import  TShirtandMerchandiseDesign from './Services_icons/MultiMedia/TShirt and Merchandise Design.webp'
import  UserExperienceDesign from './Services_icons/MultiMedia/User Experience Design.webp'
import  UserInterfaceDesign from './Services_icons/MultiMedia/User Interface Design.webp'
import  WebDesign from './Services_icons/MultiMedia/Web Design.webp'


import DisplayAdvertising from './Services_icons/Advertising/Display Advertising.webp'
import InGameAdv from './Services_icons/Advertising/In-Game Advertising.webp'
import NativeAdv from './Services_icons/Advertising/Native Advertising.webp'
import OutDoorAdv from './Services_icons/Advertising/Outdoor Advertising.webp'
import PrintAdv from './Services_icons/Advertising/Print Advertising.webp'
import PrograAdv from './Services_icons/Advertising/Programmatic Advertising.webp'
import RadioAdv from './Services_icons/Advertising/Radio Advertising.webp'
import TheaterAdv from './Services_icons/Advertising/Theatre Advertising.webp'
import TvAdv from './Services_icons/Advertising/TV Advertising.webp'
import VideoAdv from './Services_icons/Advertising/Video Advertising.webp'

import BrandAwarness from './Services_icons/Branding/Brand Awareness Campaigns.webp'
import BrandExp from './Services_icons/Branding/Brand Experience Design.webp'
import BrandGuide from './Services_icons/Branding/Brand Guidelines.webp'
import BrandID from './Services_icons/Branding/Brand Identity Exclusive.webp'
import BrandLogo from './Services_icons/Branding/Brand Logo Design.webp'
import BrandMessage from './Services_icons/Branding/Brand Messaging.webp'
import BrandName from './Services_icons/Branding/Brand Naming.webp'
import BrandPosition from './Services_icons/Branding/Brand Positioning.webp'
import BrandResearch from './Services_icons/Branding/Brand Research and Analysis.webp'
import BrandStory from './Services_icons/Branding/Brand Storytelling.webp'
import BrandTracking from './Services_icons/Branding/Brand Tracking and perfomance Evaluation.webp'
import Rebranding from './Services_icons/Branding/Rebranding Services.webp'
import VisualID from './Services_icons/Branding/Visual Identity Design.webp'

import AndroidAppDevelopment from './Services_icons/MobileApp/AndroidAppDevelopment.webp'
import AppMaintenace from './Services_icons/MobileApp/App Maintenance & Support.webp'
import AppMonetization from './Services_icons/MobileApp/App Monetization Strategy.webp'
import AppPrototype from './Services_icons/MobileApp/App Prototyping.webp'
import AppSecurity from './Services_icons/MobileApp/App Security.webp'
import AppStore from './Services_icons/MobileApp/App Store Optimization.webp'
import AppTesting from './Services_icons/MobileApp/App Testing & Quality.webp'
import CrossPlatform from './Services_icons/MobileApp/CrossPlatformAppDevelopment.webp'
import EcommerceApp from './Services_icons/MobileApp/Ecommerce App Dev.webp'
import IosApp from './Services_icons/MobileApp/iOS-App Development.webp'
import MobileApp from './Services_icons/MobileApp/Mobile App.webp'


const GlobalData = {
  company: {
    companyName: 'Leor Media',
    companyPhone: '+91-96666-43456',
    companyEmail: 'admin@leormedia.com',
    companyAddress: 'Visakhapatnam, Andhra Pradesh 530017',
    companyWhatsapp: 'https://wa.me/+919666643456',
  },

 
  DigitalMarketingServices: [
    {
      image: SearchEngineOptimization, 
      title: 'Search Engine Optimization',
      subtitle: 'Optimize your website for search engines.',
      slug: '/search-engine-optimization',
      buttonText: 'Get Quotation',
    },
    
    {
      image: ContentMarketing, 
      title: 'Content Marketing',
      subtitle: 'Create and distribute valuable content.',
      slug: '/content-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: SocialMediaMarketing, 
      title: 'Social Media Marketing',
      subtitle: 'Promote your brand on social platforms.',
      slug: '/social-media-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: EmailMarketing, 
      title: 'Email Marketing',
      subtitle: 'Engage your audience with email campaigns.',
      slug: '/email-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: PayPerClickAdvertising, 
      title: 'Pay-Per Click Advertising',
      subtitle: 'Drive traffic with targeted ads.',
      slug: '/pay-per-click-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: affiliatemarketing, 
      title: 'Affiliate Marketing',
      subtitle: 'Boost sales through affiliate programs.',
      slug: '/affiliate-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: AnalyticsandDataAnalysis, 
      title: 'Analytics and Data Analysis',
      subtitle: 'Gain insights from data for better strategies.',
      slug: '/analytics-and-data-analysis',
      buttonText: 'Get Quotation',
    },
    {
      image: ConversionRateOptimization, 
      title: 'Conversion Rate Optimization',
      subtitle: 'Improve website conversion rates.',
      slug: '/conversion-rate-optimization',
      buttonText: 'Get Quotation',
    },
    {
      image: MobileMarketing, 
      title: 'Mobile Marketing',
      subtitle: 'Target users on mobile devices.',
      slug: '/mobile-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: VideoMarketing, 
      title: 'Video Marketing',
      subtitle: 'Use videos for effective marketing.',
      slug: '/video-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: LocalSEOandMarketing, 
      title: 'Local SEO and Marketing',
      subtitle: 'Enhance local online visibility.',
      slug: '/local-seo-and-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: EcommerceMarketing, 
      title: 'Ecommerce Marketing',
      subtitle: 'Promote products in online stores.',
      slug: '/ecommerce-marketing',
      buttonText: 'Get Quotation',
    },
    {
      image: MarketingAutomation, 
      title: 'Marketing Automation',
      subtitle: 'Automate marketing tasks for efficiency.',
      slug: '/marketing-automation',
      buttonText: 'Get Quotation',
    },
    {
      image: RemarketingandRetargeting, 
      title: 'Remarketing and Retargeting',
      subtitle: 'Re-engage potential customers.',
      slug: '/remarketing-retargeting',
      buttonText: 'Get Quotation',
    },
    {
      image: InfluencerMarketing, 
      title: 'Influencer Marketing',
      subtitle: 'Collaborate with influencers for promotion.',
      slug: '/influencer-marketing',
      buttonText: 'Get Quotation',
    },
  ],

  MultiMediaServices: [
    {
      image: logodesign, // Replace with the actual image source
      title: 'Logo Design',
      slug: '/logo-design',
      buttonText: 'Get Quotation',
    },
    
    {
      image: BrandIdentityDesign, // Replace with the actual image source
      title: 'Brand Identity Design',
      slug: '/brand-identity-design',
      buttonText: 'Get Quotation',
    },
    {
      image: PrintDesign, // Replace with the actual image source
      title: 'Print Design',
      slug: '/print-design',
      buttonText: 'Get Quotation',
    },
    {
      image: WebDesign, // Replace with the actual image source
      title: 'Web Design',
      slug: '/web-design',
      buttonText: 'Get Quotation',
    },
    {
      image: UserInterfaceDesign, // Replace with the actual image source
      title: 'User Interface Design',
      slug: '/user-interface-design',
      buttonText: 'Get Quotation',
    },
    {
      image: UserExperienceDesign, // Replace with the actual image source
      title: 'User Experience Design',
      slug: '/user-experience-design',
      buttonText: 'Get Quotation',
    },
    {
      image: PackagingDesign, // Replace with the actual image source
      title: 'Packaging Design',
      slug: '/packaging-design',
      buttonText: 'Get Quotation',
    },
    {
      image: PosterandFlyerDesign, // Replace with the actual image source
      title: 'Poster and Flyer Design',
      slug: '/poster-and-flyer-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BrochureDesign, // Replace with the actual image source
      title: 'Brochure Design',
      slug: '/brochure-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BannerDesign, // Replace with the actual image source
      title: 'Banner Design',
      slug: '/banner-design',
      buttonText: 'Get Quotation',
    },
    {
      image: IllustationServices, // Replace with the actual image source
      title: 'Illustation Services',
      slug: '/illustration-services',
      buttonText: 'Get Quotation',
    },
    {
      image: InfographicDesign, // Replace with the actual image source
      title: 'Infographic Design',
      slug: '/infographic-design',
      buttonText: 'Get Quotation',
    },
    {
      image: SocialMediaGraphics, // Replace with the actual image source
      title: 'Social Media Graphics',
      slug: '/social-media-graphics',
      buttonText: 'Get Quotation',
    },
    {
      image: eBookandPrintBook, // Replace with the actual image source
      title: 'eBook and Print Book Cover Design',
      slug: '/ebook-and-print-book-cover-design',
      buttonText: 'Get Quotation',
    },
    {
      image: TShirtandMerchandiseDesign, // Replace with the actual image source
      title: 'TShirt and Merchandise Design',
      slug: '/tshirt-and-merchandise-design',
      buttonText: 'Get Quotation',
    },
    {
      image: SignageandBillboardDesign, // Replace with the actual image source
      title: 'Signage and Bill board Design',
      slug: '/signage-and-billboard-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BusinessCard, // Replace with the actual image source
      title: 'Business Card and Stationery Design',
      slug: '/business-card-and-stationery-design',
      buttonText: 'Get Quotation',
    },
    {
      image: RenderingandVisualizationBannerDesign, // Replace with the actual image source
      title: 'Rendering and Visualization',
      slug: '/rendering-and-visualization',
      buttonText: 'Get Quotation',
    },
    {
      image: MotionGraphics, // Replace with the actual image source
      title: 'Motion Graphics and Animation',
      slug: '/motion-graphics-and-animation',
      buttonText: 'Get Quotation',
    },
    {
      image: PhotoEditing, // Replace with the actual image source
      title: 'Photo Editing and Retouching',
      slug: '/phot-editing-and-retouching',
      buttonText: 'Get Quotation',
    },
  ],

  BrandingServices: [
    {
      image: BrandID, // Replace with the actual image source
      title: 'Brand Identity Design Exclusive',
      slug: '/brand-identity-design-exclusive',
      buttonText: 'Get Quotation',
    },
    
    {
      image: BrandLogo, // Replace with the actual image source
      title: 'Brand Logo Design',
      slug: '/brand-logo-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandGuide, // Replace with the actual image source
      title: 'Brand Guidelines',
      slug: '/brand-guidelines',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandPosition, // Replace with the actual image source
      title: 'Brand Positioning',
      slug: '/brand-positioning',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandStory, // Replace with the actual image source
      title: 'Brand Storytelling',
      slug: '/brand-storytelling',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandName, // Replace with the actual image source
      title: 'Brand Naming',
      slug: '/brand-naming',
      buttonText: 'Get Quotation',
    },
    {
      image: VisualID, // Replace with the actual image source
      title: 'Visual Identity Design',
      slug: '/visual-identity-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandMessage, // Replace with the actual image source
      title: 'Brand Messaging',
      slug: '/brand-messaging',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandExp, // Replace with the actual image source
      title: 'Brand Experience Design',
      slug: '/brand-experience-design',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandAwarness, // Replace with the actual image source
      title: 'Brand Awareness Campaigns',
      slug: '/brand-awareness-campaigns',
      buttonText: 'Get Quotation',
    },
    {
      image: Rebranding, // Replace with the actual image source
      title: 'Rebranding Services',
      slug: '/rebranding-services',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandResearch, // Replace with the actual image source
      title: 'Brand Research and Analysis',
      slug: '/brand-research-and-analysis',
      buttonText: 'Get Quotation',
    },
    {
      image: BrandTracking, // Replace with the actual image source
      title: 'Brand Tracking and perfomance Evaluation',
      slug: '/brand-tracking-and-perfomance-evaluation',
      buttonText: 'Get Quotation',
    },
  ],

  AdvertisingServices: [
    {
      image: DisplayAdvertising, // Replace with the actual image source
      title: 'Display Advertising',
      slug: '/display-advertising',
      buttonText: 'Get Quotation',
    },
    
    {
      image: VideoAdv, // Replace with the actual image source
      title: 'Video Advertising',
      slug: '/video-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: NativeAdv, // Replace with the actual image source
      title: 'Native Advertising',
      slug: '/native-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: PrograAdv, // Replace with the actual image source
      title: 'Programmatic Advertising',
      slug: '/programmatic-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: OutDoorAdv, // Replace with the actual image source
      title: 'Outdoor Advertising',
      slug: '/outdoor-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: PrintAdv, // Replace with the actual image source
      title: 'Print Advertising',
      slug: '/print-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: RadioAdv, // Replace with the actual image source
      title: 'Radio Advertising',
      slug: '/Radio-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: TvAdv, // Replace with the actual image source
      title: 'TV Advertising',
      slug: '/tv-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: InGameAdv, // Replace with the actual image source
      title: 'In-Game Advertising',
      slug: '/in-game-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: TheaterAdv, // Replace with the actual image source
      title: 'Theatre Advertising',
      slug: '/theatre-advertising',
      buttonText: 'Get Quotation',
    },
  ],

  AllEvents: [
    {
      image: DisplayAdvertising, // Replace with the actual image source
      title: 'Display Advertising',
      slug: '/display-advertising',
      buttonText: 'Get Quotation',
    },
    
    {
      image: VideoAdv, // Replace with the actual image source
      title: 'Video Advertising',
      slug: '/video-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: NativeAdv, // Replace with the actual image source
      title: 'Native Advertising',
      slug: '/native-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: PrograAdv, // Replace with the actual image source
      title: 'Programmatic Advertising',
      slug: '/programmatic-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: OutDoorAdv, // Replace with the actual image source
      title: 'Outdoor Advertising',
      slug: '/outdoor-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: PrintAdv, // Replace with the actual image source
      title: 'Print Advertising',
      slug: '/print-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: RadioAdv, // Replace with the actual image source
      title: 'Radio Advertising',
      slug: '/Radio-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: TvAdv, // Replace with the actual image source
      title: 'TV Advertising',
      slug: '/tv-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: InGameAdv, // Replace with the actual image source
      title: 'In-Game Advertising',
      slug: '/in-game-advertising',
      buttonText: 'Get Quotation',
    },
    {
      image: TheaterAdv, // Replace with the actual image source
      title: 'Theatre Advertising',
      slug: '/theatre-advertising',
      buttonText: 'Get Quotation',
    },
  ],

  EventsImageData : [
    {
      id: 1,
      name: 'Commercial',
      thumbnail: 'https://drive.google.com/uc?id=1zJY8kyvz9eBlSOU3NjjF6d5DKOAa-dnA',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Audio Launch',
      thumbnail: 'https://drive.google.com/uc?id=1XgueJmnnBeAVIycWk-uPEhwZfFslmv1w',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Award Nights',
      thumbnail: 'https://drive.google.com/uc?id=1w7ib-0FJwZbGRpV25JGGZMh9qQcJeVJ_',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Corporate',
      thumbnail: 'https://drive.google.com/uc?id=1AlVaLYlz5_0qEp3W1LYezf63YybPn_0x',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Cultural Night',
      thumbnail: 'https://drive.google.com/uc?id=1AnOaXHe8PMyLx5-RmIBRxG2mBwBODNJ6',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Fashion Show',
      thumbnail: 'https://drive.google.com/uc?id=1QRINFglqJmUYy-3WLKlHw_cakHl5gvyo',
      Subtitle: 'Director Name',
  
    },
    {
      id: 1,
      name: 'Product Launch',
      thumbnail: 'https://drive.google.com/uc?id=1nLGrOgaFeJbBrK7z9hrY6oVWrDluvJWk',
      Subtitle: 'Director Name',
  
    },
  ],
  
 WeddingEventsImageData : [
    /*Wedding Section */
  {
    id: 1,
    name: 'Destination Wedding',
    thumbnail: 'https://drive.google.com/uc?id=1vRXHoXxHqEypSaLDUpxZKjNalU_nAhK5',

  
  },
  {
    id: 1,
    name: 'Engagement Planner',
    thumbnail: 'https://drive.google.com/uc?id=1HyAHtfCR_T4FE6-TJ2cw0kjiIHSzzZ1c',
  
  
  },
  {
    id: 1,
    name: 'Mehandi Decor',
    thumbnail: 'https://drive.google.com/uc?id=1Y6RzIkDZtvugiq8VKn57RVIReOIt0C3D',
    
  
  },
  {
    id: 1,
    name: 'Pellikoduku Event',
    thumbnail: 'https://drive.google.com/uc?id=14WFtyQOI5yg6hRp8ZZjkG4g9AOSodonX',
   
  
  },
  {
    id: 1,
    name: 'Pellikuthuru Event',
    thumbnail: 'https://drive.google.com/uc?id=1XvPRoQ27cbtpUyESXeQNBl-L7LJQEIHD',
   
  
  },
  {
    id: 1,
    name: 'Reception',
    thumbnail: 'https://drive.google.com/uc?id=1Ng7K79aHFKuXcnzi4VnM5Z9QzM12eX-m',

  
  },
  {
    id: 1,
    name: 'Sangeeth',
    thumbnail: 'https://drive.google.com/uc?id=1h8ifZZb8sGpRp-CNNUnEVFYwTqRVKMiV',
  
  
  },
  
  ],

};


export { GlobalData ,affiliatemarketing,SearchEngineOptimization,
ContentMarketing,
SocialMediaMarketing,
EmailMarketing,
PayPerClickAdvertising,
AnalyticsandDataAnalysis,
ConversionRateOptimization,
MobileMarketing,
VideoMarketing,
LocalSEOandMarketing,
EcommerceMarketing,
MarketingAutomation,
RemarketingandRetargeting,
InfluencerMarketing};
