
const BirthdayData = [
  {
    id: 1,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 2,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 3,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 4,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 5,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 6,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 7,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 8,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 9,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 10,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 11,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
  {
    id: 12,
    name: 'Birthday',
    thumbnail: 'https://i.pinimg.com/736x/67/9d/4e/679d4ef0ec2bb195ed704a2e3f6d2ca3.jpg',
    event_title: 'EventTitle',
  },
];



export { BirthdayData };
