import React from 'react';

const Disclaimer = () => {
  return (
    <>
    <div className='disclaimer px-5 mt-6 text-white mx-4'>
    <h1 className='text-center text-2xl py-10 font-bold'> Shipping & Delivery Policy </h1>
Last updated: 2022-11-01<br/><br/>
<a className='text-lg font-medium '>Shipping is not applicable for business.</a><br />


</div>
</>
  )
}

export default Disclaimer